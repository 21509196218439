/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: "Pretendard-Medium"; */
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #7b7b7b;
  text-decoration: none;
}
a:link {
  color: none;
  text-decoration: none;
}
a:visited {
  color: #7b7b7b;
  text-decoration: none;
}
a:hover {
  color: #353535;
  text-decoration: none;
}

/* src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/paybooc-ExtraBold.woff") format("woff"); */
/* @font-face {
  font-family: "paybooc-ExtraBold";
  src: url("styles/fonts/paybooc\ ExtraBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "paybooc-Bold";
  src: url("styles/fonts/paybooc\ Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "paybooc-Medium";
  src: url("styles/fonts/paybooc\ Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "paybooc-Light";
  src: url("styles/fonts/paybooc\ Light.ttf");
  font-weight: normal;
  font-style: normal;
} */
/* ---- */

/*@font-face {*/
/*  font-family: "Pretendard-ExtraBold";*/
/*  src: url("styles/fonts/paybooc\ OTF\ ExtraBold.otf");*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*}*/

@font-face {
  font-family: 'Pretendard-ExtraBold';
  src: url("styles/fonts/Pretendard-ExtraBold.woff2") format("woff2"), url("styles/fonts/Pretendard-ExtraBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Bold';
  src: url("styles/fonts/Pretendard-Bold.woff2") format("woff2"), url("styles/fonts/Pretendard-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Medium';
  src: url("styles/fonts/Pretendard-Medium.woff2") format("woff2"), url("styles/fonts/Pretendard-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Pretendard-Light';
  src: url("styles/fonts/Pretendard-Light.woff2") format("woff2"), url("styles/fonts/Pretendard-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
